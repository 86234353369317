import { createContext, Dispatch } from "react";
import { RootInitialState, RootStateProps } from "./Reducer";

export type AppContextProps = {
  RootState: RootStateProps;
  RootDispatch: Dispatch<any>;
};
const AppContext = createContext<AppContextProps>({
  RootState: RootInitialState,
  RootDispatch: () => null,
});
export default AppContext;
