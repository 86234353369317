import { CopyRight, MainHeader, MainWrapper } from '@ifca-ui/neumorphism'
import AppContext, { AppContextProps } from 'containers/Context/Context'
import { PUBLIC_PATH } from 'containers/Module/Auth/Routes'
import { useLoggedInGuestProfileQuery } from 'generated/graphql'
import React, { ReactNode, useContext } from 'react'
import { useHistory } from 'react-router'
import HotelX from 'assets/images/app-logo-b.svg'
interface Props {
  children?: ReactNode
}

export const GuestLayout = ({ children }: Props) => {
  const history = useHistory()
  const { RootState, RootDispatch } = useContext<AppContextProps>(AppContext)
  // const {
  //   data: { loggedInGuestProfile } = { loggedInGuestProfile: null },
  // } = useLoggedInGuestProfileQuery({
  //   fetchPolicy: 'network-only',
  //   onError: error => {
  //     // eslint-disable-next-line array-callback-return
  //     error.graphQLErrors.map(({ message }) => {
  //       RootDispatch({
  //         type: 'OnSnackBar',
  //         payload: {
  //           ...RootState.OnSnackBar,
  //           Open: true,
  //           Message:
  //             message === 'Not Authenticated'
  //               ? 'Login session expired, Please login again.'
  //               : message,
  //           onClick: () =>
  //             RootDispatch({
  //               type: 'CloseSnackBar',
  //               payload: {},
  //             }),
  //         },
  //       })
  //       if (message === 'Not Authenticated') {
  //         setTimeout(() => {
  //           RootDispatch({
  //             type: 'CloseSnackBar',
  //             payload: {},
  //           })
  //           history.push(PUBLIC_PATH.LOGIN)
  //           localStorage.clear()
  //         }, 2000)
  //       }
  //     })
  //   },
  // })
  return (
    <MainWrapper>
      {' '}
      <MainHeader
        sections={{
          header: RootState?.HeaderSection?.header,
          left: RootState?.HeaderSection?.left,
          right: RootState?.HeaderSection?.right,
        }}
      />
      {children}
      <CopyRight title={<img src={HotelX} alt="logo" width={50} />} />
    </MainWrapper>
  )
}
