export default {
  root: {
    '& label.Mui-focused': {
      color: '#394D86',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#394D86',
    },
    '& .MuiInputBase-input': {
      fontWeight: '500',
      color: '#394D86',
    },
    '& .MuiFormLabel-root': {
      color: '#394D86',
    },

    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#394D86',
      },
      '&:hover fieldset': {
        borderColor: '#394D86',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#394D86',
      },
    },
  },
}
