import { Spinner } from '@ifca-ui/neumorphism'
import { GuestLayout } from 'containers/App/GuestLayout'
import React, { FC, Suspense } from 'react'
import { Route, Switch } from 'react-router'
import HomeRoutes from '../Module/Home/Routes'

/**
 * @param public & private routes
 *
 */
type RouteProps = {}
export const Routes: FC<RouteProps> = () => {
  return (
    <Switch>
      {HomeRoutes?.map((el: any, index: number) => (
        <Route key={index} {...el.props}>
          <GuestLayout>
            <Suspense fallback={<Spinner />}>{el.component}</Suspense>
          </GuestLayout>
        </Route>
      ))}

      {/* {RootRoutes?.map((el: any, index: number) => (
        <PrivateRoute key={index} {...el.props}>
          <GuestLayout>
            <Suspense fallback={<Spinner />}>{el.component}</Suspense>
          </GuestLayout>
        </PrivateRoute>
      ))} */}
    </Switch>
  )
}
