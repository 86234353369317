import palette from './palette'
import createMuiTheme from '@material-ui/core/styles/createMuiTheme'
import overrides from './override'

const theme = createMuiTheme({
  typography: {
    fontFamily: ['Poppins', 'sans-serif'].join(','),
  },
  overrides,
  palette,
  // zIndex: {
  //   appBar: 1200,
  //   drawer: 1100,
  // },
})

export default theme
