import { Reducer } from 'react'
import { IAction } from './Types'
type SnackBarType = {
  Open?: boolean
  Message?: string
  onClick?: any
}
export interface RootStateProps {
  OnLoading: boolean
  OnSnackBar?: SnackBarType
  HeaderSection?: any
}
export const RootInitialState: RootStateProps = {
  OnLoading: false,
  OnSnackBar: {
    Open: false,
    Message: '',
    onClick: () => {},
  },
  HeaderSection: {
    header: {
      headerMode: 'primary',
    },
  },
}
export const RootReducer: Reducer<RootStateProps, IAction> = (
  state,
  action
) => {
  switch (action.type) {
    case 'CloseSnackBar':
      return {
        ...state,
        OnSnackBar: {
          Open: false,
          Message: '',
          onClick: () => {},
        },
      }
    case 'Reset':
      return RootInitialState
    default:
      return { ...state, [action.type]: action.payload }
  }
}
