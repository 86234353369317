import { Button, SnackbarProps } from '@material-ui/core'
import Snackbar from '@material-ui/core/Snackbar'
import React from 'react'

interface snackBarProps extends SnackbarProps {
  message?: string
  open?: boolean
  onClick?: () => void
}
export default function StyledSnackBar(props: snackBarProps) {
  const { message, open, onClick } = props
  //   const handleClose = (
  //     event: React.SyntheticEvent | React.MouseEvent,
  //     reason?: string
  //   ) => {
  //     if (reason === 'clickaway') {
  //       return
  //     }
  //   }
  return (
    <Snackbar
      className="snackbar"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={open}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      message={
        <span id="message-id" color="secondary">
          {message}
        </span>
      }
      action={
        <Button className="color-nav-alert" size="small" onClick={onClick}>
          DISMISS
        </Button>
      }
    />
  )
}
